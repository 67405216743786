import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { EnvironmentService } from '../environment/environment.service';
import { REFRESH_TOKEN_NAME } from '../../shared/constants';

interface RefreshTokenResponseDto {
  tokens: {
    access_token: string;
    id_token: string;
    refresh_token: string;
  };
}

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  private isRefreshing: boolean;
  private refreshTokenSubject = new BehaviorSubject<string>(null);

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private environmentService: EnvironmentService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const tokenizeReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
    return next.handle(tokenizeReq).pipe(
      catchError((error: unknown) => {
        if (
          error instanceof HttpErrorResponse &&
          error.status === 401 &&
          req.url.match('refresh-token') === null
        ) {
          return this.handle401Error(req, next);
        } else {
          return throwError(() => error);
        }
      }),
    );
  }

  private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private getToken(): string {
    return localStorage.getItem('id_token');
  }

  private getRefreshToken(): string {
    return localStorage.getItem(REFRESH_TOKEN_NAME);
  }

  private handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      return this.refreshToken().pipe(
        switchMap((response) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(response.tokens.id_token);
          return next.handle(this.addToken(request, response.tokens.id_token));
        }),
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((jwt) => {
          return next.handle(this.addToken(request, jwt));
        }),
      );
    }
  }

  private refreshToken(): Observable<RefreshTokenResponseDto> {
    const refreshToken = this.getRefreshToken();

    if (!refreshToken) {
      this.authService.logOut();
      return throwError(() => new Error('No refresh token available'));
    }

    return this.http
      .post<RefreshTokenResponseDto>(
        `${this.environmentService.environments.api}admin/refresh-token`,
        {
          refreshToken: refreshToken,
          email: parseJwt(this.getToken())['email'],
        },
      )
      .pipe(
        tap((tokens) => {
          this.storeJwtToken(
            tokens.tokens.id_token,
            tokens.tokens.access_token,
          );
        }),
        catchError((error: unknown) => {
          this.authService.logOut();
          return throwError(() => error);
        }),
      );
  }

  private storeJwtToken(id_token: string, access_token: string): void {
    localStorage.setItem('id_token', id_token);
    localStorage.setItem('access_token', access_token);
  }
}

export function parseJwt(token: string): object {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );
  return JSON.parse(jsonPayload);
}
